//import website from '../assets/website.webp'
import instagram from '../assets/instagram.webp'
import github from '../assets/github.webp'
import steam from '../assets/steam.webp'
import facebook from '../assets/facebook.webp'
import spotify from '../assets/spotify.webp'
//import gamess from '../assets/gamess.webp'
// import youtube from '../assets/youtube.webp'
import linkedin from '../assets/linkedin.webp'

const items = [
// {
//     "title": "Blog",
//     "subtitle": "🌐 Lihat karyaku!",
//     "image": website,
//     "link": "https://blog.afdp.my.id/" //your personal website or portfolio  link
// },
{
    "title": "GitHub",
    "subtitle": "🏠 Proyek sumber terbuka saya",
    "image": github,
    "link": "https://github.com/ahmadfariz"
},
{
    "title": "Facebook",
    "subtitle": "Mari berteman 🤝",
    "image": facebook,
    "link": "https://www.facebook.com/ahmadfarizdwiputra"
},
{
    "title": "Instagram",
    "subtitle": "Jangan lupa ikuti saya 😉",
    "image": instagram,
    "link": "https://www.instagram.com/ahmadfarizdwiputra/"
},
{
    "title": "LinkedIn",
    "subtitle": "Portofolio 📚",
    "image": linkedin,
    "link": "https://www.linkedin.com/in/ahmadfarizdwiputra/"
},
{
    "title": "Spotify",
    "subtitle": "🎵 Coba dengerin dong",
    "image": spotify,
    "link": "https://open.spotify.com/user/ljk855mio90jui76tg762dky6?si=41e456fb3630480f&nd=1"
},
// {
//     "title": "YouTube",
//     "subtitle": "🎮 Clip Game",
//     "image": youtube,
//     "link": "https://www.youtube.com/channel/UCYUrTFfLdTWSdJi12AAxHIw/videos"
// },
{
    "title": "Steam",
    "subtitle": "🎮 Mari bermain denganku",
    "image": steam,
    "link": "https://steamcommunity.com/id/ahmadfariz/"
}
// {
//     "title": "Games Screenshots Gallery",
//     "subtitle": "🎮 Coba di lihat",
//     "image": gamess,
//     "link": "https://gamegallery.afdp.my.id/"
// }
]

export default items
